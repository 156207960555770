
export function cacheCardInfo(cardInfo) {
    return new Promise((resolve, reject)=> {
        if(cardInfo) {
            localStorage.setItem("cardId", cardInfo.cardId);
            localStorage.setItem("upgradeFlag", cardInfo.upgradeFlag);
            localStorage.setItem("bathId", cardInfo.id);
            console.log('cacheCardInfo', localStorage.getItem('bathId'));
            resolve();
        }else {
            reject();
        }
    })
}